<template>
	<b-row>
		<b-col class="table-responsive" cols="12" xl="12">
			<table class="table table-condensed table-bordered table-hover table-light ">
				<thead class="thead-dark">
					<tr>
						<th>Test No</th>
						<th>Date</th>
						<th>Analysis Type</th>
						<th>Test Result</th>
						<th>Test Result</th>
						<th>Lab</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr :class="(satir.TestSonucu ? (satir.TestSonucu == 'Positive') ? 'result-danger' : 'result-success' : '')" v-for="(satir, index) in satirlar" :key="satir.ID + '_' + index">
						<td>{{ satir.ID }}</td>
						<td>{{ tarihFormatla(satir.SDate, 'DD.MM.YYYY') }}</td>
						<td>{{ satir.AnalizTipleri }}</td>
						<td>{{ satir.TestSayisi }}</td>
						<td>{{ satir.TestSonucu }}</td>
						<td>{{ satir.Laboratuvar }}</td>
						<td>
							<button type="button" class="btn btn-lg btn-block btn-css3" @click="editForm(satir)">Add Result</button>
							<button type="button" class="btn btn-lg btn-block btn-danger" @click="approve(satir)">Approve</button>
						</td>
					</tr>
				</tbody>
			</table>
		</b-col>
		<b-col cols="6" xl="6">
			<vue-touch-keyboard class="virtual-keyboard" v-if="keyboard.visible" :layout="keyboard.layout" :cancel="hide" :accept="accept" :input="keyboard.input" />
		</b-col>
		<b-modal size="lg" title="Edit EMP Test Result" ok-only ok-title="Save" text="Edit EMP Test Result" :class="modal.className" v-model="modal.show" @ok="$event.preventDefault(); kaydet()" :ok-variant="modal.variant" :no-enforce-focus='true' @hidden="icerikTemizle">
			<b-row>
				
				<b-col class="" cols="6" xl="6">
					<label for="">Upload Report</label>
					<button @click="uppyDashboardFix" class="mb-2 btn btn-sm btn-pinterest pull-right" id="select-files">Select Files</button>
					<table class="table table-bordered table-hover table-light">
						<thead>
							<tr>
								<th>Report Name</th>
								<th>Uploaded Date</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(file, index) in files" :key="file + '_' + index">
								<td><a :href="file.Url" target="_blank">{{ file.RaporAdi }}</a></td>
								<td>{{ tarihFormatla(file.YuklenmeTarihi, 'DD.MM.YYYY') }}</td>
							</tr>
						</tbody>
					</table>
				</b-col>
				<b-col cols="6" xl="6">
					<div class="mb-2" v-for="(type, index) in selectedAnalyzeTypes" :key="type + '_' + index">
						<div class="row">
							<div class="col-md-6">
								<label class="font-weight-bold">{{ type.label }}</label>
								<input @keyup="onTypeValueChange(type, index);" :data-type-id="type.value" class="form-control" type="number" v-model="typeInputs[index]" />
								<select class="mt-1 form-control" v-model="selectedResultTypes[index]">
									<option value="0">Select</option>
									<option v-for="resultType in resultTypes" :value="resultType.ID" :key="resultType.ID">{{ resultType.Sonuc }}</option>
								</select>
							</div>
							<div class="col-md-6">
								<label class="font-weight-bold" for="unit-text">Unit</label>
								<input id="unit-text" class="form-control" type="text" v-model="selectedUnitTypes[index]"/>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col 
					v-show="false" cols="6" xl="6">
					<label for="analysis_type">Analysis Type</label>
					<v-select
					disabled
					id="analysis_type" 
					placeholder="Analysis Type"
					multiple 
					v-model="selectedAnalyzeTypes" 
					:options="analysisTypes"></v-select>
				</b-col>
			</b-row>
			<b-row v-if="modal.error">
				<b-col cols="6" xl="6">
					<hr v-if="modal.error">
					<b-alert variant="danger" :show="modal.error">{{ modal.message }}</b-alert>
				</b-col>
			</b-row>
		</b-modal>
	</b-row>
</template>

<script>

import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { headers, User, CheckAuth } from '@/shared/http';
import { SweetModal } from 'sweet-modal-vue'
import { TheMask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'
import { required, numeric, minValue } from 'vuelidate/lib/validators'
import layoutTr from '@/shared/layout-tr'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'

import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import XHRUpload from '@uppy/xhr-upload'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
	name: 'AnalysisResult',
	components: {
		vSelect
	},
	data() {
		return {
			keyboard: {
				visible: false,
				layout: layoutTr.numeric,
				input: false,
				options: {
					useKbEvents: false
				},
			},
			description: null,
			analysisTypes: [],
			selectedAnalyzeTypes: [],
			typeInputs: [],
			selectedResultTypes: [],
			selectedUnitTypes: [],
			resultTypes: [],
			unitType: null,
			TakvimID: 0,
			ID: 0,
			uppy: null,
			modal: {
				show: false,
				variant: 'primary',
				className: 'modal-success',
				error: false,
				message: null,
			},
			tarih: moment().format('YYYY-MM-DD'),
			files: []
		}
	},
	computed: {
		...mapGetters([
			'satirlar'
		])
	},
	mounted() {
		this.verileriGetir()
		this.getAnalysisTypes()
		this.getResultTypes()
		this.uploadComponent()
	},
	methods: {
		...mapActions([
			'fillSatirlar'
		]),
		uploadComponent() {
			this.uppy = Uppy()
			.use(Dashboard, {
				trigger: '#select-files',
				showProgressDetails: true,
				closeAfterFinish: true,
				closeModalOnClickOutside: true,
				proudlyDisplayPoweredByUppy: false,
				allowMultipleUploads: false
			})
			.use(XHRUpload, { 
				endpoint: `${this.$api}/api/upload-file`,
				headers: {
					//'Content-Type': 'application/json'
				}
			 })
			.on('complete', (result) => {
				this.dosyalariGetir()
				this.uppy.reset()
			})
		},
		uppyDashboardFix() {

			const { BayiID } = User

			this.uppy.setMeta({ 
				ID: this.ID,
				BayiID: BayiID
			})

			const dashboardInner = document.querySelector(".uppy-Dashboard-inner")
			dashboardInner.setAttribute('style', "z-index: 10002 !important")

			const closeModalIcon = document.querySelector(".uppy-Dashboard-close")
			closeModalIcon.style.cssText = `
				top: -5px !important;
				right: 5px !important;
				color: #e74c3c !important;
			`
		},
		accept(text) {
			this.hide();
		},
		show(e) {
			this.keyboard.input = e.target;
			this.keyboard.layout = layoutTr[e.target.dataset.layout];

			if (!this.keyboard.visible)
				this.keyboard.visible = true
		},

		hide() {
			this.keyboard.visible = false;
		},
		kaydet() {
			fetch(`${this.$api}/api/save-emp-result`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					ResultID: this.ID,
					SeciliAnalizTipleri: this.selectedAnalyzeTypes.map(type => type.value),
					Limitler: this.typeInputs,
					TestSonuclari: this.selectedResultTypes,
					BirimAdlari: this.selectedUnitTypes,
					...User
				})
			}).then(resp => resp.json())
			.then(obj => {
				
				if (obj.Success) {
					this.verileriGetir()
					this.typeInputs = []
					this.selectedAnalyzeTypes = []
					this.selectedResultTypes = []
					this.selectedUnitTypes = []
					this.modal.show = false
				} else {
					this.modal.show = true;
					this.modal.error = true;
					this.modal.message = obj.Message;
				}
				
			})
		},
		dosyalariGetir() {
			
			const { BayiID } = User

			fetch(`${this.$api}/api/GetFiles`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					ResultID: this.ID,
					BayiID: BayiID,
				})
			}).then(resp => resp.json())
			.then(obj => {

				this.files = obj
				
			})
		},
		verileriGetir() {
			
			const { ID, BayiID } = User

			fetch(`${this.$api}/api/results`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					ID: ID,
					BayiID: BayiID,
					LabDurum: 1
				})
			}).then(resp => resp.json())
			.then(obj => {

				this.fillSatirlar(obj)
				
			})
		},
		getAnalysisTypes() {
			fetch(`${this.$api}/api/analysistypes`, {
				method: 'GET',
				headers: headers,
			}).then(resp => resp.json())
			.then(obj => {

				obj.forEach(analysisType => {
					this.analysisTypes.push({
						value: analysisType.ID,
						label: analysisType.AnalizTipleri
					})
				})
			})
		},
		getResultTypes() {

			const { BayiID } = User

			fetch(`${this.$api}/api/resulttypes`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					BayiID: BayiID
				}),
			}).then(resp => resp.json())
			.then(obj => {
				this.resultTypes = obj
			})
		},
		calendarResultsDetail(ID) {
			fetch(`${this.$api}/api/CalendarTestResultsByID`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					ID: ID
				}),
			}).then(resp => resp.json())
			.then(obj => {
				this.selectedAnalyzeTypes = []
				this.selectedResultTypes = []
				obj.forEach((result, index) => {
					const type = this.analysisTypes.find((analysisType) => analysisType.value == result.AnalizTipID)
					this.selectedAnalyzeTypes.push(type)
					if (result.TestSonucu != "") {
						
						this.$set(this.selectedResultTypes, index, result.TestSonucID)
					} else {
						this.$set(this.selectedResultTypes, index, 0)
					}
					
					this.typeInputs[index] = result.TestSonucID > 0 ? result.TestSonucu : null
					this.selectedUnitTypes[index] = result.BirimAdi
				})
			})
		},
		testResultsByID(ID) {

			fetch(`${this.$api}/api/testresultsbyid`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					ID: ID
				}),
			}).then(resp => resp.json())
			.then(obj => {

				this.TakvimID = obj.TakvimID
			})
		},
		onTypeValueChange(type, index) {

			const analysisTypeID = type.value
			const analyseValue = this.typeInputs[index]
			
			//const selectedTypeID = this.selectedResultTypes[index]

			const { BayiID } = User
			
			fetch(`${this.$api}/api/GetAnalysisLimits`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify({
					BayiID: BayiID,
					TakvimID: this.TakvimID,
					AnalizTipID: analysisTypeID,
					TestSayisi: this.typeInputs[index]
				}),
			}).then(resp => resp.json())
			.then(obj => {
				this.$set(this.selectedResultTypes, index, obj.ID);
			})
			
		},
		tarihFormatla(tarih, format) {
			return moment(tarih).format(format)
		},
		approve(data) {
			const satir = this.satirlar.filter(satir => satir.ID == data.ID && satir.TestSonucu == '')

			if (satir.length == 0) {
				Swal.fire({
					title: "Warning!",
					html: `Do you want to approve test results for which <strong>result in number ${data.ID}</strong>?`,
					type: 'warning',
					confirmButtonText: 'Yes',
					showCancelButton: true,
					cancelButtonText: 'Cancel'
				}).then((swData) => {
					if(swData.value) {
						const { ID } = User
			
						fetch(`${this.$api}/api/ApproveEmpResult`, {
							method: 'POST',
							headers: headers,
							body: JSON.stringify({
								LabID: ID,
								ResultID: data.ID,
								LabDurum: 2
							}),
						}).then(resp => resp.json())
						.then(obj => {
							this.verileriGetir()
						})
					}
				})
			} else {
				Swal.fire({
					title: "Warning!",
					html: `There are test results you haven't specified yet for which <strong>result in number ${data.ID}</strong>.`,
					type: 'warning',
					confirmButtonText: 'OK',
					showCancelButton: false,
					cancelButtonText: 'Cancel'
				}).then((swData) => {
				})
			}
		},
		editForm(data) {
			this.description = data.Tanim
			this.ID = data.ID
			this.testResultsByID(data.ID)
			this.calendarResultsDetail(data.ID)
			this.dosyalariGetir()
			this.modal.show = true
		},
		icerikTemizle() {
		},
		saatFormatla(saat) {
			if(!Boolean(saat)) {
				return ''
			}

			saat = saat.replace(/:/ig, '')

			let saatArray = saat.split('')
			let sol = saatArray.splice(0, 2).join('')
			let sag = saatArray.splice(0, 2).join('')

			return `${sol}:${sag}`
		},
	}
}
</script>

<style>
.borderless {
	border: none !important;
}
.form-control:disabled, .form-control[readonly] {
	background-color: #ffffff;
}
.result-danger {
	background: -webkit-linear-gradient(left, rgba(250, 128, 114, 1) 50%, rgba(222,222,222,0) 100%, rgba(222,222,222,0) 100%, rgba(222,222,222,1) 100%)
}

.result-success {
	background: -webkit-linear-gradient(left, rgb(21, 224, 21) 50%, rgba(222,222,222,0) 100%, rgba(222,222,222,0) 100%, rgba(222,222,222,1) 100%)
}
</style>

